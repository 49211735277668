// 
// _theme.scss
// Use this to add custom theme styles
//

body.typo {
  .navbar-brand .navbar-brand-color {
    font-family: $font-family-sans-serif-alternate;
    font-weight: 500 !important;
  }

  .display-2 {
    line-height: 1.2;
  }

  h1, h2, h3 {
    &:not(i) {
      line-height: 1.4;
      font-weight: 400 !important;
    }
  }
}

.media-backdrop,
.navbar.navbar-inverse ~ .modal-backdrop-menu {
  background-color: rgb(0 0 0 / 35%);
}

body:not(.darkmode) .img-placeholder {
  filter: hue-rotate(1deg) brightness(1.1) contrast(1.2);
}

.navbar-brand {
  width: fit-content;
}

.svg-underline {
  position: absolute;
  left: 0;
  right: 0;
  max-width: none !important;
  width: 75% !important;
  margin: auto;
  bottom: -2px;
  transform: rotate(-1deg);
}

u {
  position: relative;
  text-decoration: none;

  &:after {
    content: "";
    position: absolute;
    left: -5%;
    width: 110%;
    bottom: -0.4rem;
    height: 10px;
    transform: rotate(-1deg);
    mask-image: url("data:image/svg+xml,%3Csvg fill='4ad034' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 2169.3 105.7' xml:space='preserve'%3E%3Cpath id='path20' class='st0' d='M2092.3,50.5c3.7,3.8,5.9,7.8,9.7,9.7c10.4,5.1,22.4,8.4,32.2,14c7.2,4.1,13.4,5.3,22,4.9 c4.2-0.2,8.7,3.6,13.1,5.6c-4.6,2-9,5.4-13.7,5.6c-32.9,1.5-65.9,1.5-98.6,3.9c-14.5,1.1-28.4,6.7-42.6,10.2 c-2.9,0.7-6.2,1.6-9.1,1.3c-52.6-7-105.6-7.1-158.7-6.9c-56.3,0.1-112.6-1.5-169-2.9c-57.8-1.4-115.5-3.5-173.3-5.2 c-81-2.4-162-4.2-242.9-7.1c-36.8-1.3-73.5-6.1-110.3-7c-52.6-1.4-105.2-0.6-157.8-0.8c-121.3-0.6-242.6-2-363.9-1.6 c-60.5,0.2-121.1,4.4-181.6,5.5c-60.1,1-120.2,0-180.3,0.6c-45.9,0.4-91.7,2.7-137.5,2.8c-23.9,0.1-47.8-2.5-71.6-4.4 c-2.9-0.2-7.1-2.9-7.9-5C45.9,61.8,33.2,61,22,62.3C8.5,64,4.6,59.1,2.6,50.8c-1.1-4.5-3.2-9.2-2.5-13.5c0.6-3.7,3.9-9.4,7.4-10.2 c65.3-13.5,132-21.9,199.3-25c62.4-2.9,125-2,187.6-2.1c44.2,0,88.4,0.8,132.6,1.8C587.1,3.1,647,5,706.9,6.4 c43.1,1,86.3,1,129.4,2.5c55.6,1.9,111.2,5.1,166.8,7.2c30.8,1.1,61.7,1.2,92.5,1.5c113.5,1.4,227.1,1.8,340.6,4.3 c70.9,1.6,141.8,5.9,212.6,9.5c19,1,38,6.4,56.5,5.2c20.2-1.4,39.4,0.9,59.1,1.8c24.5,1.2,49.2,0.6,73.8,0.9 c54.8,0.7,109.6,1.4,164.3,2.1c7,0.1,14.1-0.7,20.9,0.2c11.7,1.4,23,4.2,34.7,5.8C2069.9,48.8,2081.9,49.6,2092.3,50.5L2092.3,50.5z '/%3E%3C/svg%3E");
    mask-size: auto;
    mask-repeat: no-repeat;
    transition: background-color 200ms;
    background-color: $secondary;
    background-repeat: no-repeat;
    max-width: 160px;
  }
}
